import SET_PARTICIPANT_PROTOCOL_STATUS_MUTATION
  from '@/graphql/participants/SetParticipantProtocolStatusMutation.graphql'
import DELETE_PARTICIPANT_PROTOCOL_MUTATION
  from '@/graphql/participants/DeleteParticipantProtocolMutation.graphql'
import { ParticipantProtocolStatus } from '@/utils/constants'

export default {
  methods: {
    /**
     * Add the participant to a substudy or substudies
     * @param {string} participantId - the id of the participant to link
     * @param {array} substudies - the list of substudies to add the participant to
     * @returns {Promise}
     */
    addParticipantToSubstudies(participantId, substudies) {
      if (!participantId) {
        throw Error('Cannot add substudy: no participantId specified.')
      }
      if (substudies.length > 1) {
        return this.addToSubstudies(participantId, substudies)
      } else {
        return this.addToSubstudy(participantId, substudies[0].id)
      }
    },

    /**
     * Add the participant to a substudy
     * @param {string} participantId - the id of the participant to link
     * @param {string} substudyProtocolId - the id of the substudy protocol to link the participant to
     * @returns {Promise}
     */
    addToSubstudy(participantId, substudyProtocolId) {
      if (!substudyProtocolId) {
        throw Error('Cannot add substudy: no substudy protocolId specified.')
      }
      return this.$apollo.mutate({
        mutation: SET_PARTICIPANT_PROTOCOL_STATUS_MUTATION,
        variables: {
          participantId: participantId,
          protocolId: substudyProtocolId,
          protocolStatus: ParticipantProtocolStatus.PENDING // we start participants in the pending status
        }
      })
    },

    /**
     * Add the participant to multiple substudies
     * @param {string} participantId - the id of the participant to link
     * @param {array} substudies - the list of substudies to add the participant to
     * @returns {Promise}
     */
    async addToSubstudies(participantId, substudies) {
      // Add each substudy in the list.
      for (const substudy of substudies) {
        // note: we stored the protocolId in the protocol method
        await this.addToSubstudy(participantId, substudy.id)
      }
    },

    /**
     * Remove the participant from a substudy or substudies
     * @param {string} participantId - the id of the participant to unlink
     * @param {array} substudies - list of substudies to remove from the participant
     * @returns {Promise}
     */
    removeParticipantFromSubstudies(participantId, substudies) {
      if (!participantId) {
        throw Error('Cannot remove substudy: no participantId specified.')
      }
      if (substudies.length > 1) {
        return this.removeFromSubstudies(participantId, substudies)
      } else {
        return this.removeFromSubstudy(participantId, substudies[0].id)
      }
    },

    /**
     * Remove the participant from a substudy
     * @param {string} participantId - the id of the participant
     * @param {string} substudyProtocolId - the id of the substudy
     * @returns {Promise}
     */
    removeFromSubstudy(participantId, substudyProtocolId) {
      if (!substudyProtocolId) {
        throw Error('Cannot remove substudy: no substudy protocolId specified.')
      }
      return this.$apollo.mutate({
        mutation: DELETE_PARTICIPANT_PROTOCOL_MUTATION,
        variables: {
          participantId: participantId,
          protocolId: substudyProtocolId
        }
      })
    },

    /**
     * Remove the participant from multiple substudies
     * @param {string} participantId - the id of the participant
     * @param {array} substudies - a list of substudies to remove from the participant
     * @returns {Promise}
     */
    async removeFromSubstudies(participantId, substudies) {
      // Add each substudy in the list.
      for (const substudy of substudies) {
        // note: we stored the protocolId in the protocol method
        await this.removeFromSubstudy(participantId, substudy)
      }
    }
  }
}
