import GET_SUBSTUDIES_QUERY from '@/graphql/studies/GetSubstudiesQuery.graphql'
import { logError } from '@/utils/logging'
import { Features } from '@/utils/constants'

export default {
  apollo: {
    substudies() {
      return {
        query: GET_SUBSTUDIES_QUERY,
        update: data => data.getSubstudies,
        variables() {
          return {
            cohortId: this.cohortId,
            siteId: this.$route.params.siteId,
            subgroupIds: this.subgroupIds.join(',')
          }
        },
        error (error) {
          // When no substudies are found we are returned an error but we
          // do not want to log or display this error to our users.
          if (!error.message.includes('Could not find cohort group')) {
            logError(error, 'Substudies query failed.')
          }
        },
        skip() {
          return !this.cohortId || !this.subgroupIds.length
        }
      }
    }
  },
  data() {
    return {
      substudies: [],
      substudiesToUpdate: []
    }
  },
  computed: {
    hasSubstudies() {
      return this.substudies && this.substudies.length
    },

    /**
     * Determines if 'substudies' feature flag is enabled/disabled.
     */
    isSubstudiesSupported() {
      return this.$store.getters.supportedFeatures.includes(Features.SUBSTUDIES)
    }
  },
  methods: {
    toggleSubstudies (substudies) {
      this.substudiesToUpdate = substudies
    },
    /**
     * Update a participant's sub-studies
     */
    updateSubstudies: function (participantId = this.participant.id) {
      if (this.substudiesToUpdate?.add?.length) {
        this.addParticipantToSubstudies(participantId, this.substudiesToUpdate.add)
      }
      if (this.substudiesToUpdate?.remove?.length) {
        this.removeParticipantFromSubstudies(participantId, this.substudiesToUpdate.remove)
      }
    },
    /**
     * Lookup the name of a sub-study using a protocol id.
     * @param {string} protocolId
     * @returns {string} sub-study name
     */
    getSubstudyNameFromId(protocolId) {
      const result = this.substudies.find(substudy => substudy.protocol === protocolId)
      return result ? result.name : ''
    },

    /**
     * Transform a list of protocols associated with a participant into a list of sub-studies.
     * This filters out a primary study and returns only the sub-studies.
     * @param {array} protocols - a list of protocols linked to a participant
     * @returns {array} a list sub-studies the participant is participating in.
     */
    getParticipantSubstudiesFromProtocols(protocols = []) {
      return protocols
        .filter(protocol => this.substudies.some(pv => pv.protocol === protocol.protocolId))
        .map(protocol => protocol.protocolId)
    }
  }
}
