<script>
import Modal from '@/components/Modal/Modal'
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'

export default {
  components: {
    Modal,
    BfMarkdown
  },
  props: {
    substudyDetail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<template>
  <Modal
    class="substudy-detail-modal"
    @close="$emit('close')"
  >
    <template v-slot:title>
      Sub-Study Details
    </template>
    <template v-slot:content>
      <div
        class="name"
      >
        {{ substudyDetail.name }}
      </div>
      <div
        class="info"
      >
        <div>
          <span>Protocol</span>
          <span>{{ substudyDetail.versionName }}</span>
        </div>
        <div>
          <span>Cadence</span>
          <span>{{ substudyDetail.cadence }}</span>
        </div>
      </div>
      <BfMarkdown>
        {{ substudyDetail.description }}
      </BfMarkdown>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="$emit('close')"
      >
        Close
      </el-button>
    </template>
  </Modal>
</template>

<style lang="scss">
  .substudy-detail-modal {
    .name {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .info {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 28px;

      div>span:first-of-type {
        margin-right: 34px;
      }
    }
  }
</style>
