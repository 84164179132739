<script>
import BfScrollEffect from '@/components/BfScrollEffect/BfScrollEffect'
import BfCheckbox from '@/components/BfCheckbox/BfCheckbox'
import substudies from '@/mixins/queries/substudies'
import SubstudyDetailsModal from '@/components/SubstudyDetailsModal/SubstudyDetailsModal'
import participant from '@/mixins/queries/participant'

export default {
  components: {
    BfScrollEffect,
    BfCheckbox,
    SubstudyDetailsModal
  },
  mixins: [substudies, participant],
  props: {
    editableParticipantData: {
      type: Object,
      required: true
    },
    visits: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    profileView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedSubstudyDetail: undefined,
      activeSubstudies: [],
      substudiesToRemove: [],
      substudiesToAdd: []

    }
  },
  computed: {
    /**
     * cohortId and subgroupId's are needed to retrieve sub-studies.
     */
    cohortId() {
      return this.editableParticipantData.cohortId
    },

    /**
     * Subgroups are listed under a different property on the enrollment and edit views, we need to accept both.
     */
    subgroupIds() {
      if (this.editableParticipantData.subgroupIds) {
        return this.editableParticipantData.subgroupIds
      }
      if (this.editableParticipantData.subgroups) {
        return this.editableParticipantData.subgroups
      }
      return null
    },

    /**
     * Filters out visits without entered data.
     * We only use visit data to check if data exists for a substudy.
     * TODO: This should be a more sophisticated check. But, this logic will need to change
     *       when visit windows are added.. so we will leave this update for later.
     *       ?: Will instance at 0% completion be accepeted when unlinking sub-study?
     *
     * @returns {array}
     */
    visitsWithData() {
      return this.visits.filter(visit => visit.instanceID !== null)
    },

    /**
     * Generate the prompt.
     */
    prompt() {
      if (!this.cohortId || !this.subgroupIds.length) {
        return 'Select a cohort and subgroup in order to determine what sub-studies this participant is eligible for.'
      }

      // Enroll Participant view
      if (this.cohortId && this.subgroupIds && this.hasSubstudies && !this.profileView) {
        return 'This participant is eligible for the following sub-studies:'
      }

      // Participant Profile view (not in edit mode) of a participant who is enrolled in >=1 sub-studies.
      if (this.substudies.length && this.disabled) {
        return 'This participant is enrolled in the following sub-studies:'
      }

      // Participant Profile view, in edit mode.
      if (this.visits.length && !this.disabled) {
        return `
          To enroll the participant, select an eligible sub-study and save your changes.
          Once a visit has begun, the participant cannot be removed from the sub-study.
        `
      }

      // Enrollment, Participant Profile of a participant not enrolled in any sub-studies.
      if (this.hasSubstudies) {
        return 'This participant is a candidate for the following sub-studies:'
      } else {
        return `
          Sub-study eligibility is based on the selected cohort and subgroup.
          This participant is not eligible for any sub-studies at this time.
        `
      }
    }

  },
  created() {
  },
  methods: {
    /**
     * Handle substudy selection
     *
     * @param substudy
     * @param state
     */
    substudyToggle(substudy, state) {
      if (this.disabled) { return }
      if (state) {
        this.substudiesToAdd.push(substudy)
        this.substudiesToRemove = this.substudiesToRemove.filter(item => item.id !== substudy.id)
      } else {
        this.substudiesToRemove.push(substudy)
        this.substudiesToAdd = this.substudiesToAdd.filter(item => item.id !== substudy.id)
      }
      this.$emit('toggle-substudies', { add: this.substudiesToAdd, remove: this.substudiesToRemove })
    },

    /**
     * Determine if any data has been added for a given substudy
     * If data is present, do not allow selection/unselection of this substudy option.
     *
     * @param {object} substudy
     */
    substudyHasData(substudy) {
      const substudyVisitsWithData = this.visitsWithData.filter(
        visit => visit.protocolId === substudy.id
      )
      return substudyVisitsWithData.length > 0
    },

    /**
     * Displays the modal for sub-study detailed information.
     *
     * @param {object} substudy
     */
    viewSubstudyDetails(substudy) {
      this.selectedSubstudyDetail = substudy
    },

    substudyValue (substudy) {
      return this.participant?.protocols?.find(item => item.protocolId === substudy.id)
    }
  }
}
</script>

<template>
  <div
    v-loading="$apollo.queries.substudies.loading"
    class="substudy-selector"
  >
    <p>
      {{ prompt }}
    </p>
    <BfScrollEffect
      v-if="hasSubstudies"
      class="substudies-scroll"
      effect="inset"
    >
      <div
        v-for="(substudy, index) in substudies"
        :key="index"
        class="substudy-option"
        :class="{ 'disabled' : disabled || substudyHasData(substudy) }"
      >
        <BfCheckbox
          name="substudies"
          :label="substudy.name"
          :value="substudyValue(substudy)"
          :disabled="disabled || substudyHasData(substudy)"
          @input="substudyToggle(substudy, $event)"
        />
        <el-button
          v-if="isSubstudiesSupported"
          type="ghost"
          class="details-trigger"
          @click="viewSubstudyDetails(substudy)"
        >
          View Details
        </el-button>
      </div>
    </BfScrollEffect>

    <SubstudyDetailsModal
      v-if="!!selectedSubstudyDetail"
      :substudy-detail="selectedSubstudyDetail"
      @close="selectedSubstudyDetail = undefined"
    />
  </div>
</template>

<style lang="scss">
.substudy-selector {
  p:last-of-type {
    margin-bottom: 0;
  }

  .scroll-wrap {
    max-height: 22rem;
    margin-top: 1.5rem;
  }

  .substudy-option {
    position: relative;
    margin-bottom: 1rem;

    &.disabled {
      opacity: .6;
      cursor: not-allowed;
      pointer-events: none;
    }

    .bf-checkbox {
      margin-bottom: 0;
    }
  }

  .input-label {
    margin-bottom: 0;
  }

  .details-trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto;
  }
}

#app .el-button.details-trigger {
  @include text-style('interface', 'medium', 'medium');

  span:first-of-type {
    padding-right: 20px;
  }
}
</style>
